<template>
    <transports-module-component></transports-module-component>
</template>

<script>
    import TransportsModuleComponent from "@/components/admin/modules/TransportsModuleComponent";

    export default {
        name: "TransportsModule",
        title: "Gestión de Transportes | Turismo BC",
        components: {TransportsModuleComponent}
    }
</script>

<style scoped>

</style>